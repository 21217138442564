.aboutBody {
  background: url(../../assets/images/bg2.png) center top no-repeat;
  background-size: 19.18rem 6.48rem;
}

.aboutBody .content {
  width: 11.36rem;
  height: 7.09rem;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 1rem;
}

.aboutBody .content h1 {
  font-size: 0.44rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0.2rem;
}

.aboutBody .content .em {
  font-size: 0.24rem;
  font-weight: 500;
  color: #fff;
  margin: 0;
}

.aboutBody .content p {
  font-size: 0.24rem;
  color: #fff;
  line-height: 0.4rem;
}

.aboutBody .content .gap {
  height: 1rem;
}