/*reset*/

body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td {
  margin: 0;
  padding: 0;
}

body,
button,
input,
select,
textarea {
  font: 0.14rem/1 Helvetica,sans-serif;
  line-height: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

address,
cite,
dfn,
em,
var {
  font-style: normal;
}

code,
kbd,
pre,
samp {
  font-family: courier new,courier,monospace;
}

small {
  font-size: 0.12rem;
}

ul,
ol {
  list-style: none;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

legend {
  color: #000;
}

fieldset,
img {
  border: 0;
}

button,
input,
select,
textarea {
  font-size: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus {
  outline: 0;
}

body * {
  max-height: 10000rem;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  -webkit-appearance: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

body {
  font-size: 0.14rem;
  /*pxno*/
  color: #333333;
  font-family: Helvetica,sans-serif;
}

.bttn {
  background: Transparent;
  border: none;
  cursor: pointer;
  *overflow: visible;
}

.bttn.disabled {
  background-color: #cccccc;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}