html,
body {
  margin: 0;
  padding: 0;
  background-color: #202223;
}

.wrapper {
  background-color: #202223;
  width: 19.2rem;
  margin: 0 auto;
}

.header {
  height: 2.32rem;
  width: 100%;
}

.logo {
  width: 1.59rem;
  height: 0.64rem;
  float: left;
  margin: 0.45rem 0 0 0.45rem;
}

.logoImg {
  display: block;
  width: 1.59rem;
  height: 0.64rem;
}

.mainNav {
  float: right;
  margin: 0.7rem 1.4rem 0 0;
}

.mainNav .item {
  float: left;
}

.mainNav a {
  font-size: 0.24rem;
  color: rgba(255, 255, 255, 0.8);
  margin-right: 1.1rem;
  text-decoration: none;
}

.mainNav a.current {
  color: #61BE8A;
  text-decoration: underline;
}

.mainNav .current {
  color: #61BE8A;
  text-decoration: underline;
}

.bodyer {
  min-height: 9rem;
}

.footer {
  background-color: #202223;
}

.footer .logo2 {
  width: 0.83rem;
  height: 1.19rem;
  display: block;
  margin: 0 auto;
}

.footer .slogan {
  font-size: 0.33rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 0.6rem;
  text-align: center;
  margin-top: 0.35rem;
}

.footer .links {
  text-align: center;
  margin: 1rem auto;
}

.footer .links a {
  margin: 0 0.5rem;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.24rem;
}

.footer .share {
  text-align: center;
  margin: 1rem auto 0 auto;
}

.footer .share a {
  margin: 0 0.8rem;
  display: inline-block;
}

.footer .shareIcon {
  height: 0.3rem;
}

.footer .ipcInfo {
  text-align: center;
  margin: 0.5rem auto;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.16rem;
  line-height: 1.5;
}

.footer a {
  color: rgba(255, 255, 255, 0.8);
}