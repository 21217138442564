.policyBody .content {
  width: 11.36rem;
  height: 7.09rem;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 1rem;
}

.policyBody .termsContent {
  font-size: 0.24rem;
  height: 100%;
  color: #fff;
}

.policyBody .termsContent .hding {
  font-size: 0.28rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0.2rem;
}

.policyBody .termsContent .bold {
  font-weight: 500;
}

.policyBody .termsContent .title {
  font-size: 0.28rem;
  font-weight: 600;
  margin-bottom: 0.08rem;
  margin-top: 0.2rem;
}

.policyBody .termsContent .subTitle {
  font-size: 0.26rem;
  font-weight: 600;
  margin-bottom: 0.05rem;
}

.policyBody .termsContent .p {
  font-size: 0.26rem;
  line-height: 0.3rem;
  margin-bottom: 0.2rem;
}

.policyBody .termsContent .list_item {
  font-size: 0.26rem;
  line-height: 0.3rem;
  margin-bottom: 0.18rem;
}

.policyBody .termsContent .sublist_item {
  font-size: 0.26rem;
  line-height: 0.3rem;
  margin-bottom: 0.1rem;
}

.policyBody .termsContent .en {
  margin-top: 1rem;
  font-family: serif;
}