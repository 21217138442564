.indexBody {
  background: url(../../assets/images/bg1.png) center top no-repeat;
  background-size: 19.18rem 6.48rem;
}

.indexBody .content {
  width: 11.36rem;
  height: 7.09rem;
  margin: 0 auto;
  overflow: hidden;
}

.indexBody .phoneImg {
  margin-top: -1rem;
  float: left;
  width: 5.15rem;
  height: 8.44rem;
}

.indexBody h1 {
  font-size: 0.6rem;
  line-height: 0.74rem;
  color: #fff;
  font-weight: 400;
  white-space: nowrap;
  margin-top: 1.1rem;
}

.indexBody .qrcodes {
  margin-top: 0.8rem;
  overflow: hidden;
}

.indexBody .qrcodes .item {
  width: 1.5rem;
  float: left;
  width: 0.9rem;
  margin-right: 1rem;
}

.indexBody .qrcodes .item:last-child {
  margin-right: 0;
}

.indexBody .qrcodes img {
  width: 0.9rem;
  height: 0.9rem;
  display: block;
  background-color: #fff;
  margin: 0 auto;
}

.indexBody .qrcodes h2 {
  font-size: 0.18rem;
  font-weight: normal;
  margin-top: 0.1rem;
  text-align: center;
  color: #fff;
  white-space: nowrap;
}